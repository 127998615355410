import { useQueryClient } from '@tanstack/react-query'
import { convertDateValues, ILocation, IMessageSideEffect, setLocale, SupportedLocaleCode } from 'core'
import { useEffect } from 'react'

import { identifyUser, resetUserIdentity } from '../analytics/analytics'
import { setServerDevice } from '../device'
import { appEnv, isNotBot, isWeb } from '../env'
import { pusher } from '../pusher'
import { onCookieChange } from '../util/cookie'
import { pubSub } from '../util/pubsub'
import { onDataChange } from '../util/storage'
import { useColorScheme } from './useColorScheme'
import { useMessageStore } from './useMessageStore'
import { useNotificationStore } from './useNotificationStore'
import { loadSavedState, useStore } from './useStore'
import { useWebExtension } from './useWebExtension'

type UseStoreEffectsParams = {
  sideEffectHandler?: (sideEffect: IMessageSideEffect) => Promise<void>
}

export const useStoreEffects = ({ sideEffectHandler }: UseStoreEffectsParams) => {
  const hydrated = useStore(state => state.hydrated)
  const hydrate = useStore(state => state.hydrate)
  const isAuthenticated = useStore(state => state.isAuthenticated)
  const locale = useStore(state => state.locale)
  const translations = useStore(state => state.translations)
  const themeName = useStore(state => state.themeName)
  const jobScope = useStore(state => state.jobScope)
  const currentUserId = useStore(state => state.currentUserId)
  const shouldLoadMessages = useStore(state => state.shouldLoadMessages)
  const currentTeam = useStore(state => state.currentTeam)
  const setIsAuthenticated = useStore(state => state.setIsAuthenticated)
  const setStats = useStore(state => state.setStats)

  const setIsWidget = useStore(state => state.setIsWidget)
  const defaultLocation = useStore(store => store.location)
  const addTranslation = useStore(state => state.addTranslation)
  const setTheme = useStore(state => state.setTheme)
  const setSelectedLocation = useStore(state => state.setSelectedLocation)
  const setUserData = useStore(state => state.setUserData)
  const setIsLoadingUser = useStore(state => state.setIsLoadingUser)

  const addNotification = useNotificationStore(state => state.addNotification)
  const setNotificationBadgeCount = useNotificationStore(state => state.setNotificationBadgeCount)
  const addChannel = useMessageStore(state => state.addChannel)
  const addMessage = useMessageStore(state => state.addMessage)
  const setChannels = useMessageStore(state => state.setChannels)

  const queryClient = useQueryClient()
  const { isWidget } = useWebExtension()
  const colorScheme = useColorScheme(themeName)

  useEffect(() => {
    setIsWidget(isWidget)
  }, [setIsWidget, isWidget])

  useEffect(() => {
    if (!jobScope) {
      if (defaultLocation) {
        setSelectedLocation({
          locationId: defaultLocation.locationId,
          locationName: defaultLocation.cityName as ILocation['name']
        })
      }
      return
    }
    const [locationId, locationName] = jobScope.split('|')

    if (!locationId || !locationName) {
      if (defaultLocation) {
        setSelectedLocation({
          locationId: defaultLocation.locationId,
          locationName: defaultLocation.cityName as ILocation['name']
        })
      }
      return
    }

    setSelectedLocation({
      locationId: locationId as ILocation['id'],
      locationName: locationName as ILocation['name']
    })
  }, [defaultLocation, jobScope, setSelectedLocation])

  useEffect(() => {
    if (locale in translations) {
      return
    }

    // @todo drop once we human translate these (search "tempLocaleFix" for other instances)
    const localeFile: SupportedLocaleCode = locale === 'es-419' ? 'es-ES' : locale === 'fr-CA' ? 'fr-FR' : locale

    const translationRequest = fetch(`${appEnv.frontendUrl}/locales/${localeFile}.json`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        ...(appEnv.stagingFrontendToken ? { Authorization: `Basic ${appEnv.stagingFrontendToken}` } : {}),
        'Content-Type': 'application/json'
      }
    })

    ;(async () => {
      const translations = await translationRequest.then(res => res.json())
      addTranslation(locale, translations)
    })()
  }, [locale, translations, addTranslation])

  // pub/sub used to sync state among tabs
  useEffect(() => {
    const unsubscribe = pubSub.subscribe(message => {
      if (message.type === 'Login') {
        queryClient.resetQueries()

        if (message.userId) {
          identifyUser(message.userId)
        }
      }

      if (message.type === 'Logout') {
        queryClient.resetQueries()
        resetUserIdentity()
      }

      if (message.type === 'LoadedUserData') {
        const { response, channelsResponse } = message

        if (response.success) {
          setServerDevice(response.data.device)
          setNotificationBadgeCount(response.data.notificationBadgeCount)

          setChannels(channelsResponse?.success ? channelsResponse.channels : [])

          setStats(response.data.stats)

          if (response.data.user?.locale) {
            setLocale(response.data.user.locale)
          }

          setIsAuthenticated(!!response.data.user)

          setUserData({
            user: response.data.user,
            location: response.data.location,
            currentUserId: response.data.currentUserId,
            resumeImportState: response.data.resumeImportState,
            extensionState: response.data.extensionState,
            shouldLoadMessages: response.data.shouldLoadMessages,
            subscriptions: response.data.subscriptions,
            applied: response.data.applied,
            saved: response.data.saved,
            ambitionPlusSubscription: response.data.ambitionPlusSubscription,
            hasStripeSubscription: response.data.hasStripeSubscription
          })
        }

        setIsLoadingUser(false)
      }

      if (message.type === 'ImportedResume') {
        queryClient.resetQueries()
      }
    })

    return unsubscribe
  }, [setChannels, setIsAuthenticated, setNotificationBadgeCount, setStats, setUserData, setIsLoadingUser, queryClient])

  useEffect(() => {
    if (!isWeb) {
      loadSavedState().then(state => {
        if (!state?.themeName) {
          setTheme(colorScheme === 'dark' ? 'dark' : 'light')
        }
      })
    }
  }, [colorScheme, setTheme])

  useEffect(() => {
    if (!hydrated) {
      hydrate()
    }

    // update the store with the latest cookie values set by the server
    const unsubscribeFromCookieChanges = isWeb ? onCookieChange(() => hydrate()) : () => {}

    // synchronize state among app instances via storage
    const unsubscribeFromStorageChanges = onDataChange(key => {
      // key is null when all of storage is cleared
      if (key === 'state' || key === null) {
        hydrate()
      }
    })

    return () => {
      unsubscribeFromCookieChanges()
      unsubscribeFromStorageChanges()
    }
  }, [hydrated, hydrate])

  useEffect(() => {
    // only connect to pusher if it's a real user (no bots or guests), or if it's native.
    // using streaming on web for assistant, and that's the only guest service needing real-time data at the moment.
    if (!(shouldLoadMessages && isNotBot && currentUserId && (isAuthenticated || !isWeb))) {
      return
    }

    /*
    @todo switch back to this when we use pusher user channels again

    const { unsubscribe } = pusher.subscribeToUser(({ event, data }) => {
      if (event === 'ChannelCreated') {
        addChannel(data.channel)
      }

      if (event === 'MessageCreated') {
        addMessage(data.message)
      }

      if (event === 'NotificationCreated') {
        addNotification(data.notification)
      }
    })

    return unsubscribe
    */
    const { unsubscribe } = pusher.subscribeToUser(currentUserId, ({ event, data }) => {
      if (event === 'ChannelCreated') {
        addChannel(data.channel)
      }

      if (event === 'MessageCreated' || event === 'MessageUpdated') {
        if (isWeb) {
          addMessage(data.message)
          return
        }

        addMessage(convertDateValues(data.message))
        const sideEffects = (data?.message?.commands || []).map((command: any) => command.sideEffects).flat()
        if (sideEffectHandler) {
          for (const sideEffect of sideEffects) {
            sideEffectHandler(sideEffect)
          }
        }
      }

      if (event === 'NotificationCreated') {
        addNotification(data.notification)
      }
    })

    return unsubscribe
  }, [shouldLoadMessages, currentUserId, isAuthenticated, addChannel, addMessage, addNotification, sideEffectHandler])

  useEffect(() => {
    if (!currentTeam?.id) {
      return
    }

    const { unsubscribe } = pusher.subscribeToTeam(currentTeam.id, ({ event, data }) => {
      if (event === 'ChannelCreated') {
        addChannel(data.channel)
      }

      if (event === 'MessageCreated') {
        addMessage(data.message)
      }

      if (event === 'NotificationCreated') {
        addNotification(data.notification)
      }
    })

    return unsubscribe
  }, [currentTeam?.id, addChannel, addMessage, addNotification])
}

import { getResumeImportState } from 'core'
import { useState } from 'react'

import { pubSub, useLoadUserData, useStore } from '~/lite'

let reloadedData = false

export const usePollResumeImportState = () => {
  const resumeImportState = useStore(state => state.resumeImportState)
  const loadUserData = useLoadUserData()
  const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval>>()

  if (
    (resumeImportState === 'Pending' ||
      resumeImportState === 'Processing' ||
      resumeImportState === 'RecommendationsAdded' ||
      resumeImportState === 'Retry') &&
    !intervalId
  ) {
    const intervalId = setInterval(async () => {
      const response = await getResumeImportState()

      if (response.success) {
        // if we're done processing, stop polling
        if (response.state === 'Success' || response.state === 'Error' || response.state === null) {
          clearInterval(intervalId)
          setIntervalId(undefined)
          reloadedData = false
        }

        // if we have the recommendations, load them
        if (response.state === 'RecommendationsAdded' && !reloadedData) {
          reloadedData = true
          await loadUserData()
        }

        // if we finished, reload the data
        if (response.state === 'Success' || response.state === 'Error') {
          pubSub.publish({ type: 'ImportedResume' })
          await loadUserData()
        }
      }
    }, 2500)

    setIntervalId(intervalId)
  }
}

import React from 'react'

import { useStoreEffects } from '~/lite'

type EffectHandlerProps = {
  children?: React.ReactNode
}

export const EffectHandler = ({ children }: EffectHandlerProps) => {
  useStoreEffects({})

  return <>{children}</>
}

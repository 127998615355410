import { useEffect, useState } from 'react'

import { isServer } from '../env'

type ColorScheme = 'light' | 'dark'

const getColorScheme = (fallback: ColorScheme): ColorScheme =>
  isServer ? fallback : window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'

export const useColorScheme = (fallback: ColorScheme): ColorScheme => {
  const [colorScheme, setColorScheme] = useState(getColorScheme(fallback))

  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      setColorScheme(event.matches ? 'dark' : 'light')
    })
  }, [])

  return colorScheme
}

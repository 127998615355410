import type { INotification } from 'core'
import React, { useEffect, useState } from 'react'

import { subscribeToToasts, useNotificationStore } from '~/lite'

import { showToast, ToasterProvider } from './ToasterProvider'

export const Toaster: React.FC = () => {
  const { notifications } = useNotificationStore()

  const [, setPrevNotificationIds] = useState<INotification['id'][]>([])

  useEffect(() => {
    setPrevNotificationIds(prevNotificationIds => {
      const newNotifications = notifications.filter(notification => !prevNotificationIds.includes(notification.id))

      newNotifications.forEach(notification => showToast({ notification }))

      return [...prevNotificationIds, ...newNotifications.map(notification => notification.id)]
    })
  }, [notifications])

  useEffect(() => subscribeToToasts(toast => showToast(toast)), [])

  return <ToasterProvider />
}

import { useMemo, useState } from 'react'

import { sendMessageToExtension } from '../util/extension'
import { useOnHydrate } from './useOnHydrate'

let readySent = false

export const useWebExtension = () => {
  const [isMock, setIsMock] = useState(true)

  useOnHydrate(() => {
    setIsMock(false)
  })

  const isWidget = useMemo(() => {
    if (isMock) {
      return false
    }

    try {
      return window.self !== window.top
    } catch (e) {
      return true
    }
  }, [isMock])

  if (isWidget && !readySent) {
    readySent = true
    sendMessageToExtension({ type: 'a:app-ready' })
  }

  return { isWidget }
}

import 'setimmediate'

import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Domain, getEndpointsForMode, init as initCore, reactQueryClientOptions, setCountry, setLocale } from 'core'
import React, { StrictMode } from 'react'
import { render as renderRoot } from 'react-dom'
import { hydrateRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { AppProvider, getThemeValues, useRoutes, useStore } from '~/navigation'

interface IAppProps {
  domain?: Domain
  subdomain?: string
}

const App: React.FC<IAppProps> = ({ domain, subdomain }) => {
  const routes = useRoutes(domain, subdomain)
  const router = createBrowserRouter(routes)
  return <RouterProvider router={router} />
}

export const render = (pageContext: any): any => {
  const {
    mode,
    reactQueryState,
    isAuthenticated,
    appInstanceId,
    domain,
    subdomain,
    locale,
    country,
    translations,
    themeName,
    jobScope,
    source,
    agentCommandId
  } = pageContext
  const container = document.getElementById('root')
  const queryClient = new QueryClient({ defaultOptions: reactQueryClientOptions })

  const { apiServerUrl } = getEndpointsForMode(mode)

  if (country) {
    setCountry(country)
  }

  setLocale(locale)
  initCore({ apiServerUrl, appInstanceId, appVersion: null })

  useStore.setState({
    isAuthenticated,
    locale,
    ...getThemeValues(themeName),
    jobScope,
    source,
    agentCommandId
  })
  useStore.getState().addTranslation(locale, translations)

  const app = (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={reactQueryState}>
          <HelmetProvider>
            <AppProvider>
              <App domain={domain} subdomain={subdomain} />
            </AppProvider>
          </HelmetProvider>
        </Hydrate>
      </QueryClientProvider>
    </StrictMode>
  )

  if (container && container.innerHTML !== '') {
    hydrateRoot(container!, app)
  } else {
    renderRoot(app, container)
  }
}

import { generateUUID, init as initCore } from 'core'
import React, { useEffect, useState } from 'react'

import {
  appVersion,
  constants,
  init as initAnalytics,
  isWeb,
  SafeAreaProvider,
  ThemeProvider,
  trackEvent,
  useLoadUserData,
  useOnHydrate,
  usePollResumeImportState,
  useStore,
  ViewEventProperties
} from '~/lite'

import { EffectHandler } from './EffectHandler'
import { NavigationContainer } from './NavigationContainer'
import { Toaster } from './Toaster'

// on web, we init core in apps/web/src/renderer/_default.page.*.tsx (has access to the app instance id)
// on native, we init core here since there's no equivalent.
if (!isWeb) {
  if (!appVersion) {
    throw new Error('Missing app version in native environment')
  }

  initCore({ apiServerUrl: constants.apiServerUrl, appInstanceId: generateUUID(), appVersion })
}

interface IAppProvider {
  children?: React.ReactNode
}

export const AppProvider: React.FC<IAppProvider> = ({ children }) => {
  const [isHydrated, setIsHydrated] = useState(false)
  const loadUserData = useLoadUserData()
  const user = useStore(state => state.user)
  const theme = useStore(state => state.theme)

  usePollResumeImportState()

  useOnHydrate(() => {
    initAnalytics()
    setIsHydrated(true)
  })

  useEffect(() => {
    if (isHydrated && !user) {
      loadUserData()
    }
  }, [isHydrated, user, loadUserData])

  // @todo benchmark the impact waiting on the store has on boot time.
  // currently we're waiting on locale to be set for guests before api requests are made
  // (not an issue for signed in users since we load it from their record)
  return (
    <SafeAreaProvider>
      <ThemeProvider theme={theme}>
        <NavigationContainer
          onRouteChange={(prevRouteName, newRouteName) => {
            if (newRouteName !== prevRouteName) {
              const screenEvents: {
                [routeName: string]: Exclude<ViewEventProperties['screen'], 'Collection' | 'Position'>
              } = {
                Collections: 'Home',
                ChannelMasterDetail: 'Messages',
                ChannelList: 'Messages',
                Notifications: 'Notifications',
                Profile: 'UserProfile'
              }

              const screen = newRouteName ? screenEvents[newRouteName] : null

              if (screen) {
                trackEvent('View', { screen })
              }
            }
          }}
        >
          <Toaster />
          <EffectHandler>{children}</EffectHandler>
        </NavigationContainer>
      </ThemeProvider>
    </SafeAreaProvider>
  )
}
